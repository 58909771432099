import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertsService } from '../../services/alerts.service';
import { Alert } from '../../types/alert';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-alerts-provider',
  templateUrl: './alerts-provider.component.html',
  styleUrls: ['./alerts-provider.component.scss']
})
export class AlertsProviderComponent {
  alerts$: Observable<Alert[]> = this._alertsService.alerts$;

  constructor(private _alertsService: AlertsService) {}
}
