import {
  Directive,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ElementRef,
  EventEmitter,
  forwardRef,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  HostBinding,
  Input,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnDestroy,
  Output,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioInputDirective } from './radio-input.directive';

let nextId = 0;

/**
 * @deprecated Use PuiInputRadioGroup instead
 */
@Directive({
  selector: '[appRadioGroup]',
  exportAs: 'appRadioGroup',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupDirective),
      multi: true
    }
  ]
})
export class RadioGroupDirective implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _radios: Set<RadioInputDirective> = new Set<RadioInputDirective>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _value = null;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled: boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get disabled() {
    return this._disabled;
  }

  @Input()
  name = `ngb-radio-${nextId++}`;

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  @Input()
  set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueChange = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onTouched = () => {};

  onRadioChange(radio: RadioInputDirective): void {
    this.writeValue(radio.value);
    this.onChange(radio.value);
    this.valueChange.emit(radio.value);
  }

  onRadioValueUpdate(): void {
    this._updateRadiosValue();
  }

  register(radio: RadioInputDirective): void {
    this._radios.add(radio);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this._updateRadiosDisabled();
  }

  unregister(radio: RadioInputDirective): void {
    this._radios.delete(radio);
  }

  // @ts-expect-error TS7006
  writeValue(value): void {
    this._value = value;
    this._updateRadiosValue();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _updateRadiosValue(): void {
    this._radios.forEach(radio => radio.updateValue(this._value));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _updateRadiosDisabled(): void {
    this._radios.forEach(radio => radio.updateDisabled());
  }
}
