import { Component, inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from '@app/core/form/form.service';
import { PuiDialogRef } from '@awarenow/profi-ui-core';

interface Tag {
  name: string;
  children: { id: number; name: string }[];
}

const closeModal = () => {
  const modal = inject(NgbActiveModal, { optional: true });
  const dialogRef = inject(PuiDialogRef, { optional: true });

  return () => {};
};

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-tags-selector-modal',
  templateUrl: './tags-selector-modal.component.html',
  styleUrls: ['./tags-selector-modal.component.scss']
})
export class TagsSelectorModalComponent implements OnInit {
  // @ts-expect-error TS2564
  tagsForm: UntypedFormGroup;
  tags: Tag[] = [];
  filteredTags: Tag[] = [];
  selected: number[] = [];

  @Input()
  // @ts-expect-error TS7032
  set selectedTags(ids) {
    // @ts-expect-error TS7006
    this.selected = ids.map(i => i);
  }

  @Input()
  // @ts-expect-error TS7032
  set allTags(tags) {
    this.tags = [];
    for (const tag of tags) {
      if (!tag.parents) {
        this.tags.push({
          name: tag.name,
          children: []
        });
      } else {
        this.tags[this.tags.length - 1].children.push(tag);
      }
    }
    this.filteredTags = this.tags;
  }

  @Input()
  tagsLimit = 5;

  constructor(
    private _modal: NgbActiveModal,
    private _formBuilder: UntypedFormBuilder,
    private _form: FormService
  ) {}

  ngOnInit(): void {
    this.tagsForm = this._formBuilder.group({
      search: ['', [Validators.required]]
    });
  }

  cancel(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this._modal.dismiss();
  }

  save(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this._modal.close({ selected: this.selected });
  }

  selectTag(id: number): void {
    if (!this.selected.includes(id)) {
      if (this.selected.length < this.tagsLimit) {
        this.selected.push(id);
      }
    } else {
      this.selected.splice(this.selected.indexOf(id), 1);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  filter() {
    const query = this.tagsForm.controls['search'].value.toLowerCase();
    if (query.trim().length === 0) {
      this.filteredTags = this.tags;
    } else {
      this.filteredTags = this.tags.map(tag => ({
        ...tag,
        children: tag.children.filter(i => i.name.toLowerCase().includes(query))
      }));
    }
  }
}
