import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { RadioGroupDirective } from './radio-group.directive';

/**
 * @deprecated Use PuiInputRadio instead
 */
@Directive({ selector: '[appRadioInput][type=radio]' })
export class RadioInputDirective implements OnDestroy {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _checked: boolean;
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private _value: any = null;

  @Input()
  // @ts-expect-error TS2564
  name: string;

  @Input('value')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(value: any) {
    this._value = value;
    const stringValue = value ? value.toString() : '';
    this._renderer.setProperty(this._element.nativeElement, 'value', stringValue);
    this._group.onRadioValueUpdate();
  }

  @Input('disabled')
  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled !== false;
  }

  @HostBinding('checked')
  get checked(): boolean {
    return this._checked;
  }

  @HostBinding('disabled')
  get isElementDisabled(): boolean {
    return this._group.disabled || this._disabled;
  }

  @HostBinding('name')
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get nameAttr() {
    return this.name || this._group.name;
  }

  @HostListener('blur')
  onBlur(): void {
    this.focused = false;
  }

  @HostListener('focus')
  onFocus(): void {
    this.focused = true;
  }

  @HostListener('change')
  onChange(): void {
    this._group.onRadioChange(this);
  }

  set focused(isFocused: boolean) {
    if (!isFocused) {
      this._group.onTouched();
    }
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures, @typescript-eslint/no-explicit-any
  get value(): any {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private _group: RadioGroupDirective,
    private _renderer: Renderer2,
    private _element: ElementRef<HTMLInputElement>
  ) {
    this._group.register(this);
  }

  ngOnDestroy(): void {
    this._group.unregister(this);
  }

  // @ts-expect-error TS7006
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateValue(value) {
    this._checked = this.value === value;
  }

  updateDisabled(): void {}
}
