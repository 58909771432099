import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { CLIENT_STRIPE_CREDIT_CARDS_ENDPOINT } from '@app/shared/constants/endpoints';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PaymentGateways } from '@app/shared/enums/payment-gateways';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { ICard, IPaypalAccount } from '../interfaces/card';
import { NotificationsService } from '@awarenow/profi-ui-core';

@Injectable()
export class ClientWalletService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _cards = new ReplaySubject<ICard[]>(1);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _paypalAccounts = new ReplaySubject<IPaypalAccount[]>(1);
  private somethingWrongSTR = `Something wrong`;
  private serverErrorSTR = `Server error`;
  private requestFailedSTR = `The request failed`;

  isFetching = false;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private readonly _runtimeConfigService: RuntimeConfigService
  ) {}

  cards$(): Observable<ICard[]> {
    return this._cards.asObservable();
  }

  paypalAccounts$(): Observable<IPaypalAccount[]> {
    return this._paypalAccounts.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh$(clear?: boolean): Observable<any> {
    if (clear) {
      this._cards.next([]);
      this._paypalAccounts.next([]);
    }

    const uri = this.getEndpointUri();
    if (!uri) {
      return of();
    }

    this.isFetching = true;

    return this.http.get(uri).pipe(
      finalize(() => {
        this.isFetching = false;
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((data: any) => {
        if (data && data.result) {
          if (data.paypalAccounts) {
            this._paypalAccounts.next(this.mapServerResponseToPaypalAccounts(data));
          }
          this._cards.next(this.mapServerResponseToCards(data));
        }
      }),
      catchError(err => {
        this.notifications.error(`${this.serverErrorSTR}. ${err.error.msg}`);
        return throwError(err);
      })
    );
  }

  deleteCard(cardId: number): void {
    if (this.isFetching) {
      return;
    }

    const uri = this.getEndpointUri();
    if (!uri) {
      return;
    }

    this.isFetching = true;

    this.http
      .delete(`${uri}/${cardId}`)
      .pipe(
        finalize(() => {
          this.isFetching = false;
        }),
        // @ts-expect-error TS2345
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mergeMap((data: any) => {
          if (data && data.result && data.paymentResponse && data.paymentResponse.deleted) {
            return this.refresh$();
          }
          this.notifications.error(`${this.somethingWrongSTR}. ${this.requestFailedSTR}`);
          return null;
        }),
        catchError(err => {
          this.notifications.error(`${this.serverErrorSTR}. ${err.error.msg}`);
          return throwError(err);
        })
      )
      .subscribe();
  }

  setAsDefault(cardId: number): void {
    if (this.isFetching) {
      return;
    }

    const uri = this.getEndpointUri();
    if (!uri) {
      return;
    }

    this.isFetching = true;

    this.http
      .post(`${uri}/set-default/`, { cardId })
      .pipe(
        finalize(() => {
          this.isFetching = false;
        }),
        // @ts-expect-error TS2345
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mergeMap((data: any) => {
          if (data && data.result && data.defaultCardId) {
            return this.refresh$();
          }
          this.notifications.error(`${this.somethingWrongSTR}. ${this.requestFailedSTR}`);
          return null;
        }),
        catchError(err => {
          this.notifications.error(`${this.serverErrorSTR}. ${err.error.msg}`);
          return throwError(err);
        })
      )
      .subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private getEndpointUri() {
    let uri = null;
    switch (this._runtimeConfigService.get('paymentGateway')) {
      case PaymentGateways.STRIPE:
        uri = CLIENT_STRIPE_CREDIT_CARDS_ENDPOINT;
        break;
    }

    return uri;
  }

  // @ts-expect-error TS7006
  private mapServerResponseToCards(data): ICard[] {
    // @ts-expect-error TS7006
    return data.cards.map(card => ({
      id: card.id,
      isDefault: card.id === data.defaultCard,
      type: card.brand.toLowerCase(),
      number: card.last4,
      exp_year: card.exp_year,
      exp_month: card.exp_month
    }));
  }

  // @ts-expect-error TS7006
  private mapServerResponseToPaypalAccounts(data): IPaypalAccount[] {
    // @ts-expect-error TS7006
    return data.paypalAccounts.map(paypalAccount => ({
      id: paypalAccount.id,
      email: paypalAccount.email,
      isDefault: paypalAccount.id === data.defaultCard
    }));
  }
}
