import { Pipe, PipeTransform } from '@angular/core';
import { normalizeFullName } from '@app/shared/utils/full-name';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {
  transform(...params: Parameters<typeof normalizeFullName>): string | null {
    return normalizeFullName.apply(null, params);
  }
}
