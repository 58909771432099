import { NgModule } from '@angular/core';
import { PuiInputNumberDirective } from '@libs/shared/directives/pui-input-number.directive';
import { PuiOverflowAutoHeightDirective } from '@libs/shared/directives/pui-overflow-auto-height.directive';
import { TimezonePipe } from '@libs/shared/pipes/abbr-timezone.pipe';
import { AvailableDateFnPipe } from '@libs/shared/pipes/available-date-fn.pipe';
import { AvailableSeatsPipe } from '@libs/shared/pipes/available-seats.pipe';
import { DateRangePipe } from '@libs/shared/pipes/date-range.pipe';
import { DurationPipe } from '@libs/shared/pipes/duration.pipe';
import { FilterPipe } from '@libs/shared/pipes/filter.pipe';
import { OrderByPipe } from '@libs/shared/pipes/order-by.pipe';
import { SafePipe } from '@libs/shared/pipes/safe.pipe';
import { SearchPipe } from '@libs/shared/pipes/search.pipe';
import { PricePipe } from '@libs/shared/pipes/service-price/price.pipe';
import { SessionsLandingPageLinkPipe } from '@libs/shared/pipes/sessions-landing-page-link.pipe';
import { SortPipe } from '@libs/shared/pipes/sort.pipe';
import { UserNamePipe } from '@libs/shared/pipes/user-name.pipe';

import { PuiMaxMinNumberDirective } from './directives/pui-max-min-number.directive';
import { ClearHtmlPipe } from './pipes/clear-html.pipe';
import { ConnectionTypePipe } from './pipes/connection-type.pipe';
import { CurrentOccupancyRatePipe } from './pipes/current-occupancy-rate.pipe';
import { IsEmptyPipe } from './pipes/is-empty.pipe';
import { JsonPipe } from './pipes/json.pipe';
import { MapPipe } from './pipes/map.pipe';
import { TimezoneOffsetPipe } from './pipes/timezone-offset.pipe';
import { CallbackPipe } from '@libs/shared/pipes/callback.pipe';
import { SessionFullLandingLinkPipe } from '@libs/shared/pipes/session-full-landing-link.pipe';
import { IfTeamAdminDirective } from '@libs/shared/directives/if-team-admin.directive';
import { BookingDateRangePipe } from '@libs/shared/pipes/booking-date-range.pipe';
import { BookingDatePipe } from '@libs/shared/pipes/booking-date.pipe';

@NgModule({
  declarations: [
    PuiOverflowAutoHeightDirective,
    PuiInputNumberDirective,
    PuiMaxMinNumberDirective,
    FilterPipe,
    OrderByPipe,
    SearchPipe,
    TimezonePipe,
    DurationPipe,
    MapPipe,
    SafePipe,
    SortPipe,

    UserNamePipe,
    DateRangePipe,
    IsEmptyPipe,
    CurrentOccupancyRatePipe,
    JsonPipe,
    PricePipe,
    TimezoneOffsetPipe,
    AvailableSeatsPipe,
    AvailableDateFnPipe,
    SessionsLandingPageLinkPipe,
    CallbackPipe,
    SessionFullLandingLinkPipe,
    IfTeamAdminDirective,
    BookingDateRangePipe,
    BookingDatePipe
  ],
  imports: [ConnectionTypePipe, ClearHtmlPipe],
  exports: [
    IfTeamAdminDirective,
    PuiOverflowAutoHeightDirective,
    PuiInputNumberDirective,
    PuiMaxMinNumberDirective,
    FilterPipe,
    OrderByPipe,
    SearchPipe,
    TimezonePipe,
    DurationPipe,
    MapPipe,
    SafePipe,
    SortPipe,
    ClearHtmlPipe,
    UserNamePipe,
    DateRangePipe,
    IsEmptyPipe,
    CurrentOccupancyRatePipe,
    JsonPipe,
    ConnectionTypePipe,
    PricePipe,
    TimezoneOffsetPipe,
    AvailableSeatsPipe,
    AvailableDateFnPipe,
    SessionsLandingPageLinkPipe,
    CallbackPipe,
    SessionFullLandingLinkPipe,
    BookingDateRangePipe,
    BookingDatePipe,
    ClearHtmlPipe
  ]
})
export class SharedModule {}
