import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearHtml',
  standalone: true
})
export class ClearHtmlPipe implements PipeTransform {
  transform(value?: string): unknown {
    // Replace HTML tags and entities with an empty string
    return value ? value?.replace(/<[^>]+>|&[^;]+;/g, '') : '';
  }
}
