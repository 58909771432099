<div class="ui-selector-container ui-selector-origin" matAutocompleteOrigin #defaultOrigin="matAutocompleteOrigin">
  <ui-chip-list
    #chipList
    [multiple]="multiple"
    [disabled]="disabled"
    [selectable]="false"
    [required]="required"
    [placeholder]="placeholder"
    [aria-orientation]="ariaOrientation"
    [errorStateMatcher]="errorStateMatcher"
    [tabIndex]="tabIndex"
    [compareWith]="compareWith">
    <ui-chip
      *ngFor="let chip of value"
      [value]="chip"
      [removable]="isItemRemovable(chip)"
      [selectable]="false"
      (removed)="onChipRemove($event)">
      {{ chip | displayVia : getChipLabel }}
    </ui-chip>

    <ui-chip-list-input
      #input
      [placeholder]="placeholder"
      [separatorKeysCodes]="separatorKeysCodes"
      [chipList]="chipList.originChipList"
      [autocomplete]="auto"
      [autocompleteConnectedTo]="autocompleteConnectedTo || defaultOrigin"
      [autocompleteDisabled]="autocompleteDisabled"
      (inputTokenEnd)="onInputTokenEnd($event)"
      (valueChanged)="onInputValueChange($event)"></ui-chip-list-input>
  </ui-chip-list>
  <pui-icon class="selector-icon" (click)="click($event)" size="s" svgIcon="dropdown-arrow"></pui-icon>
</div>

<ui-autocomplete
  class="ui-autocomplete ui-selector-autocomplete mat-elevation-z0 {{ overlayPanelClassName }}"
  #auto="uiAutocomplete"
  [multiple]="multiple"
  [displayWith]="getOptionLabel"
  (opened)="opened.emit()"
  (closed)="closed.emit()"
  (optionSelected)="onToggleOption($event)"
  disableRipple>
  <mat-option
    class="ui-option option-without-checkbox option-select-all"
    *ngIf="!!options?.length"
    (onSelectionChange)="onToggleAll()"
    (click)="$event.stopPropagation();$event.preventDefault();"
    data-qa-id="ui-selector-multiple-select-all-checkbox">
    {{ text['Select all'] }}
  </mat-option>

  <mat-option
    class="ui-option"
    *ngFor="let option of options"
    [value]="option.value"
    [ngClass]="option.classList"
    [class.option-without-checkbox]="pseudoCheckboxDisabled"
    [class.default-template]="!optionTemplateRef"
    [disabled]="option.disabled"
    [id]="option.id">
    <ng-template
      *ngTemplateOutlet="optionTemplateRef || option.content || defaultOptionTemplateRef;
      context: {$implicit: option.value}"></ng-template>
  </mat-option>

  <!--  Option for empty list-->
  <mat-option class="ui-option option-without-checkbox empty-list" *ngIf="!options?.length && emptyListTemplateRef">
    <ng-template *ngTemplateOutlet="emptyListTemplateRef"></ng-template>
  </mat-option>
</ui-autocomplete>

<ng-template #defaultOptionTemplateRef let-option>
  <div class="option-default-wrapper">{{ option | displayVia : getOptionLabel }}</div>
</ng-template>
