const normalizeFullName = (
  user: { firstName: string; lastName: string } | null,
  {
    breakFirstWord
  }: {
    breakFirstWord?: boolean;
  } = {}
): null | string => {
  if (!user) {
    return null;
  }

  const firstName = user.firstName || '';
  const lastName = user.lastName || '';
  const fullName = `${firstName} ${lastName}`.trim();

  return breakFirstWord ? fullName.replace(/\s/, '\n') : fullName;
};

export { normalizeFullName };
