import { StarRatingModule } from 'angular-star-rating';
import { ClickOutsideModule } from 'ng-click-outside';
import { ClipboardModule } from 'ngx-clipboard';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { RouterModule } from '@angular/router';
import { BillingModule } from '@app/modules/billing/billing.module';
import { AlertMessageModule } from '@app/modules/ui-kit/alert-message/alert-message.module';
import { CheckboxModule } from '@app/modules/ui-kit/checkbox/checkbox.module';
import { FormModule } from '@app/modules/ui-kit/form/form.module';
import { UserInfoModule } from '@app/modules/ui-kit/user-info/user-info.module';
import { UiClientSelectorMultipleModule } from '@app/modules/ui-kit/widgets/ui-client-selector-multiple';
import { StyleDirective } from '@app/screens/our-community/directives/style.directive';
import { BtnMenuComponent } from '@app/shared/components/btn-menu/btn-menu.component';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { DropdownActionsMenuComponent } from '@app/shared/components/dropdown-actions-menu/dropdown-actions-menu.component';
import { LanguageSelectorComponent } from '@app/shared/components/language-selector/language-selector.component';
import { LinkMenuComponent } from '@app/shared/components/link-menu/link-menu.component';
import { ServiceLabelModule } from '@app/shared/components/service-label/service-label.module';
import { ServiceTypeModule } from '@app/shared/components/service-type/service-type.module';
import { SessionPaymentFormatterComponent } from '@app/shared/components/session-payment-formatter/session-payment-formatter.component';
import { SessionPaymentFormatterModule } from '@app/shared/components/session-payment-formatter/session-payment-formatter.module';
import { TimeToSessionStartComponent } from '@app/shared/components/time-to-session-start/time-to-session-start.component';
import { CutBeginningOfStringPipe } from '@app/shared/pipes/cut-beginning-of-string';
import { FilterByIconNamePipe } from '@app/shared/pipes/filter-by-icon-name.pipe';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FilterByNameModule } from '@app/shared/pipes/filter-by-name/filter-by-name.module';
import { FilterByNamePipe } from '@app/shared/pipes/filter-by-name/filter-by-name.pipe';
import { LocalDatePipe } from '@app/shared/pipes/local-date.pipe';
import { NumberAbbreviationPipe } from '@app/shared/pipes/number-abbreviation';
import { SafePipeModule } from '@app/shared/pipes/safe/safe.module';
import { SessionConnectionTypeIconModule } from '@app/shared/pipes/session-connection-type-icon/session-connection-type-icon.module';
import { SessionConnectionTypeIconPipe } from '@app/shared/pipes/session-connection-type-icon/session-connection-type-icon.pipe';
import { SessionRangeFormatterModule } from '@app/shared/pipes/session-range-formatter/session-range-formatter.module';
import { SessionRangeFormatterPipe } from '@app/shared/pipes/session-range-formatter/session-range-formatter.pipe';
import { SimpleRoundModule } from '@app/shared/pipes/simple-round/simple-round.module';
import { SortOnlySessionDateModule } from '@app/shared/pipes/sort-only-session-date/sort-only-session-date.module';
import { SortSessionByPipe } from '@app/shared/pipes/sort-only-session-date/sort-session-by.pipe';
import { UserNameModule } from '@app/shared/pipes/user-name/user-name.module';
import { UserNamePipe } from '@app/shared/pipes/user-name/user-name.pipe';
import {
  PuiAvatarModule,
  PuiButtonModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiInputModule,
  PuiList2Module,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';

import { ActionsMenuComponent } from './components/actions-menu/actions-menu.component';
import { CheckboxReactiveComponent } from './components/checkbox-reactive/checkbox-reactive.component';
import { ClientSessionFeedbackComponent } from './components/client-session-feedback/client-session-feedback.component';
import { ClientWalletCardComponent } from './components/client-wallet-card/client-wallet-card.component';
import { ClientWalletCardsComponent } from './components/client-wallet-cards/client-wallet-cards.component';
import { ClientsSelectorComponent } from './components/clients-selector/clients-selector.component';
import { ClientsSelectorModalComponent } from './components/clients-selector/components/clients-selector-modal/clients-selector-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { CopyValueInputComponent } from './components/copy-value-input/copy-value-input.component';
import { FormControlErrorComponent } from './components/form-control-error/form-control-error.component';
import { HorizontalSliderComponent } from './components/horizontal-slider/horizontal-slider.component';
import { IconSelectorComponent } from './components/icon-selector/icon-selector.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputTelComponent } from './components/input-tel/input-tel.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { PaymentPendingDateComponent } from './components/payment-pending-date/payment-pending-date.component';
import { SessionPaymentStatusComponent } from './components/session-payment-status/session-payment-status.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepsModule } from './components/steps/steps.module';
import { SwitchToAlternativeAccountConfirmModalComponent } from './components/switch-to-alternative-account-confirm-modal/switch-to-alternative-account-confirm-modal.component';
import { TagsSelectorModalComponent } from './components/tags-selector/components/tags-selector-modal/tags-selector-modal.component';
import { TagsSelectorComponent } from './components/tags-selector/tags-selector.component';
import { TimelineComponentComponent } from './components/timeline-component/timeline-component.component';
import { UniversalSelectorModalComponent } from './components/universal-selector/components/selector-modal/universal-selector-modal.component';
import { UniversalSelectorComponent } from './components/universal-selector/universal-selector.component';
import { UploadAvatarModalComponent } from './components/upload-avatar-modal/upload-avatar-modal.component';
import { WeekdaysSelectorComponent } from './components/weekdays-selector/weekdays-selector.component';
import { WidgetModalComponent } from './components/widget-modal/widget-modal.component';
import { WrongSystemTimeModalComponent } from './components/wrong-system-time-modal/wrong-system-time-modal.component';
import { AdditionalInfoDirective } from './directives/additional-info';
import { ClampDirective } from './directives/clamp.directive';
import { CollapsibleDirective } from './directives/collapsible.directive';
import { LowerCaseFormControlDirective } from './directives/lower-case-form-control.directive';
import { RadioGroupDirective } from './directives/radio/radio-group.directive';
import { RadioInputDirective } from './directives/radio/radio-input.directive';
import { ScrollToFirstInvalidDirective } from './directives/scroll-to-first-invalid.directive';
import { ScrollTopDirective } from './directives/scroll-top.directive';
import { EmailValidationDirective } from './form-validators/email/emai-validator.directive';
import { CeilPipe } from './pipes/ceil.pipe';
import { CutStringPipe } from './pipes/cut-string.pipe';
import { EmbedVideoPipe } from './pipes/embed-video.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FreeSessionOfferFormatterPipe } from './pipes/free-session-offer-formatter.pipe';
import { HumanizeSessionStartDatePipe } from './pipes/humanize-session-start-date.pipe';
import { InvitationPaymentDateFormatterPipe } from './pipes/invitation-payment-date-formatter.pipe';
import { PaymentDatePipe } from './pipes/payment-date.pipe';
import { PaymentPendingDatePipe } from './pipes/payment-pending-date.pipe';
import { PaymentTypePipe } from './pipes/payment-type.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { ServiceRangeFormatterPipe } from './pipes/service-range-formatter.pipe';
import { SessionDatePipe } from './pipes/session-date.pipe';
import { SessionDateTimePipe } from './pipes/session-datetime.pipe';
import { SessionDurationPipe } from './pipes/session-duration.pipe';
import { StripHtmlTagsPipe } from './pipes/strip-html-tags.pipe';
import { WeekDayPipe } from './pipes/week-day.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { UploadImageModalModule } from './components/upload-image-modal/upload-image-modal.module';
import { ImageCropperComponent } from 'ngx-image-cropper';

@NgModule({
  imports: [
    SafeHtmlPipe,
    CommonModule,
    FormsModule,
    ButtonModule,
    ImageCropperComponent,
    NgSelectModule,
    NgbModule,
    ClickOutsideModule,
    ClipboardModule,
    ReactiveFormsModule,
    RouterModule,
    StarRatingModule,
    NgScrollbarModule,
    StepsModule,
    PlatformStyleModule,
    UiClientSelectorMultipleModule,
    MatAutocompleteModule,
    AlertMessageModule,
    CheckboxModule,
    FormModule,
    UserInfoModule,
    SafePipeModule,
    BillingModule,
    PuiInputModule,
    PuiIconModule,
    PuiButtonModule,
    FilterByNameModule,
    PuiList2Module,
    PuiDropdownModule,
    PuiAvatarModule,
    SortOnlySessionDateModule,
    SessionRangeFormatterModule,
    UserNameModule,
    SessionPaymentFormatterModule,
    SessionConnectionTypeIconModule,
    ServiceLabelModule,
    ServiceTypeModule,
    PuiTypographyModule,
    TranslocoModule,
    UploadImageModalModule
  ],
  declarations: [
    // Components:
    ActionsMenuComponent,
    BtnMenuComponent,
    ClientSessionFeedbackComponent,
    ClientWalletCardComponent,
    ClientWalletCardsComponent,
    ClientsSelectorComponent,
    ClientsSelectorModalComponent,
    CopyValueInputComponent,
    FormControlErrorComponent,
    InputPasswordComponent,
    InputTelComponent,
    LanguageSelectorComponent,
    LinkMenuComponent,
    StepperComponent,
    TagsSelectorComponent,
    TagsSelectorModalComponent,
    TimelineComponentComponent,
    TimeToSessionStartComponent,
    UploadAvatarModalComponent,
    UniversalSelectorComponent,
    UniversalSelectorModalComponent,
    WrongSystemTimeModalComponent,
    SessionPaymentStatusComponent,
    ConfirmModalComponent,
    WidgetModalComponent,
    SwitchToAlternativeAccountConfirmModalComponent,
    HorizontalSliderComponent,
    WeekdaysSelectorComponent,
    NotificationModalComponent,
    PaymentPendingDateComponent,
    IconSelectorComponent,
    DropdownActionsMenuComponent,
    // Directives:
    ClampDirective,
    CollapsibleDirective,
    LowerCaseFormControlDirective,
    RadioGroupDirective,
    RadioInputDirective,
    ScrollToFirstInvalidDirective,
    ScrollTopDirective,
    StyleDirective,
    AdditionalInfoDirective,
    // Validators
    EmailValidationDirective,
    // Pipes:
    CeilPipe,
    CutStringPipe,
    CutBeginningOfStringPipe,
    FreeSessionOfferFormatterPipe,
    EmbedVideoPipe,
    HumanizeSessionStartDatePipe,
    InvitationPaymentDateFormatterPipe,
    ReplacePipe,
    SessionDateTimePipe,
    SecondsToTimePipe,
    ServiceRangeFormatterPipe,
    SessionDatePipe,
    WeekDayPipe,
    CheckboxReactiveComponent,
    StripHtmlTagsPipe,
    FileSizePipe,
    PaymentPendingDatePipe,
    PaymentTypePipe,
    SessionDurationPipe,
    PaymentDatePipe,
    FilterByIconNamePipe,
    NumberAbbreviationPipe,
    LocalDatePipe
  ],
  exports: [
    // Modules:
    NgSelectModule,
    ClickOutsideModule,
    StepsModule,
    AlertMessageModule,
    CheckboxModule,
    ButtonModule,
    UserInfoModule,
    ServiceLabelModule,
    SimpleRoundModule,
    ServiceTypeModule,
    UploadImageModalModule,
    // Components:
    ActionsMenuComponent,
    CheckboxReactiveComponent,
    ClientsSelectorComponent,
    ClientsSelectorModalComponent,
    ClientWalletCardComponent,
    ClientWalletCardsComponent,
    CopyValueInputComponent,
    FormControlErrorComponent,
    InputPasswordComponent,
    InputTelComponent,
    StepperComponent,
    TagsSelectorComponent,
    TimelineComponentComponent,
    TimeToSessionStartComponent,
    UniversalSelectorComponent,
    UniversalSelectorModalComponent,
    UploadAvatarModalComponent,
    HorizontalSliderComponent,
    WeekdaysSelectorComponent,
    SessionPaymentStatusComponent,
    ConfirmModalComponent,
    LanguageSelectorComponent,
    LinkMenuComponent,
    BtnMenuComponent,
    NotificationModalComponent,
    PaymentPendingDateComponent,
    IconSelectorComponent,
    DropdownActionsMenuComponent,
    SessionPaymentFormatterComponent,
    // Directives:
    ClampDirective,
    CollapsibleDirective,
    LowerCaseFormControlDirective,
    RadioGroupDirective,
    RadioInputDirective,
    ScrollToFirstInvalidDirective,
    ScrollTopDirective,
    StyleDirective,
    AdditionalInfoDirective,
    // Pipes
    CeilPipe,
    CutStringPipe,
    CutBeginningOfStringPipe,
    FreeSessionOfferFormatterPipe,
    HumanizeSessionStartDatePipe,
    InvitationPaymentDateFormatterPipe,
    EmbedVideoPipe,
    ReplacePipe,
    SecondsToTimePipe,
    SessionDateTimePipe,
    ServiceRangeFormatterPipe,
    SessionDatePipe,
    WeekDayPipe,
    StripHtmlTagsPipe,
    FileSizePipe,
    SafePipeModule,
    PaymentPendingDatePipe,
    PaymentTypePipe,
    SessionDurationPipe,
    PaymentDatePipe,
    FilterByIconNamePipe,
    NumberAbbreviationPipe,
    FilterByNamePipe,
    SortSessionByPipe,
    SessionRangeFormatterPipe,
    UserNamePipe,
    SessionConnectionTypeIconPipe,
    LocalDatePipe,
    SafeHtmlPipe
  ],
  providers: [
    PaymentPendingDatePipe,
    PaymentTypePipe,
    NumberAbbreviationPipe,
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    SessionDurationPipe,
    DatePipe
  ]
})
export class SharedModule {}
